import React from "react"
import Box from "@mui/material/Box";
import {AgGridReact} from "ag-grid-react";
import {ColumnDefs, DefaultColDef} from "./column"
import PropTypes from "prop-types";

const MasterGroupTreeView = React.memo(({data}) => {


    return (
        <Box style={{flexGrow: 1}}>
            <AgGridReact
                className={'ag-theme-balham'}
                columnDefs={ColumnDefs}
                defaultColDef={DefaultColDef}
                rowData={data}
            />
        </Box>
    )
})

MasterGroupTreeView.propTypes = {
    data: PropTypes.array.isRequired,
}

export default MasterGroupTreeView
