import React, {useEffect} from "react";
import IconButton from "@mui/material/IconButton";
import {Place as PlaceIcon} from "@mui/icons-material";
import PropTypes from "prop-types";

const ViewMapButtonRenderer = ({onClick, value, data, node, type, view}) => {

    return (
        <>
            {data.latitude && data.longitude && <IconButton
                onClick={() => onClick({latitude: data.latitude, longitude: data.longitude})}
                size="small">
                <PlaceIcon size="small" />
            </IconButton>}
        </>
    )
}

ViewMapButtonRenderer.propTypes = {
    clicked: PropTypes.func.isRequired,
    data: PropTypes.any,
}

export default ViewMapButtonRenderer
